.small-box .icon > i {
    font-size: 90px;
    position: absolute;
    right: 15px;
    top: 15px;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
}

.data-display span{display: inline-block; width: 40%;}

.text-right{text-align: right;}
.text-center{text-align: center;}

#loader{
    position:fixed;
    z-index: 10000;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: url("./icons/loader.gif")  no-repeat center rgba(255,255,255,0.5);
    background-size: 60% auto;
}

/* .previewForm span{} */